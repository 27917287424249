import React from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label, Col } from "reactstrap";
import { FaMapMarkedAlt } from "react-icons/fa";
import { fetchLandUse, onChangeSoilType } from "../../../Actions/landUse";
import { API, CLIENT } from "../../../consts_path";
import * as common from "../../Common/common";
// import { createBrowserHistory } from 'history'

const updateFunc = async (parentKey, landuse, inputs, history, token) => {
  const form = new FormData();
  const updateForUrl = common.makeUrlIndex(API.URL.landuse, inputs.id);
  Object.keys(landuse.input).map((key) =>
    form.append(`${parentKey}[${key}]`, landuse[parentKey][key])
  );
  await fetch(updateForUrl, {
    credentials: "same-origin",
    method: "PUT",
    body: form,
    headers: {
      "X-CSRF-Token": token,
    },
  }).then((_res) => {
    // const history = createBrowserHistory()
    if (!_res.ok) {
      alert("予期せぬ障害が発生しました。地点選択からやり直してください。");
      // throw Error((window.location = CLIENT.URL.root));
    // } else {
    //   history.push(common.makeUrlIndex(CLIENT.URL.weather, inputs.id));
    }
  });
};

class LandUseType extends React.Component {
  componentDidMount() {
    const { LandUse, input } = this.props;
    const urlForLandUse = common.makeUrlIndex(API.URL.landuse, input.id);
    LandUse(urlForLandUse);
    let landUse = { input: {id: input.id, land_use_id: 2}};
    this.update(landUse, input);
  }
  update = (landUse, inputs) => {
    const { history, csrfToken } = this.props;
    const input = Object.keys(landUse)[0];
    updateFunc(input, landUse, inputs, history, csrfToken);
  };
  render() {
    const { landUse, changeSoilType, input } = this.props;
    return (
      <div>
        <h3>
          <FaMapMarkedAlt id="mapIcon" /> 土地利用条件
        </h3>
        <hr />
        {landUse && (
          <FormGroup row id="soil">
            <Label for="landuse" id="land_use" sm={3}>
              土地利用条件の選択
            </Label>
            <Col sm={6}>
              <Input
                type="select"
                name="land_use_id"
                id="landuse"
                required
                onChange={(e) => {
                  changeSoilType(e, landUse, "LAND_USE");
                  this.update(landUse, input);
                }}
                value={landUse.input.land_use_id || 2}
              >
                {(() => {
                  return landUse.land_uses.map((m) => (
                    <option key={`${m.id}`} value={m.id}>
                      {m.name}({m.en_name})
                    </option>
                  ));
                })()}
              </Input>
            </Col>
          </FormGroup>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  landUse: state.landUseData,
  input: state.input,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  LandUse: (url) => dispatch(fetchLandUse(url)),
  changeSoilType: (e, geo, variable) =>
    dispatch(onChangeSoilType(e, geo, variable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandUseType);

